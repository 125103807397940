import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Center, Flex, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useTranslations } from 'contexts/LocalizationContext';
import { useScannedRelevosContext } from 'contexts/ScannedRelevosContext';
import { StatusCodes } from 'http-status-codes';

import { getApplicationUsers } from '../../../../api/applicationUser';
import QrCodeScanner from '../../../../common/components/QrCodeScanner/QrCodeScanner';
import { RestaurantSelect } from '../../../../common/components/RestaurantSelect/RestaurantSelect';
import { useRestaurantsData } from '../../../../services/restaurants';
import { relevoAssignmentRestaurantSelectStyle } from '../../styles/RelevoAssignmentRestaurantSelectStyle';
import RelevoScanner from '../RelevoScanner';
import SelectCategories from '../SelectCategories';
import Autocomplete from './Autocomplete';
import { ItemAssignmentSelectMode, UserSelectMode } from './types';

const UserSelect = ({ mode, scanMode }: { mode: UserSelectMode; scanMode?: ItemAssignmentSelectMode }): JSX.Element => {
  const translations = useTranslations();
  const { applicationUser, setApplicationUser, restaurantId, setRestaurantId } = useScannedRelevosContext();
  const { uId } = useParams<{ uId?: string }>();
  const [code, setCode] = useState('');
  const { restaurants } = useRestaurantsData();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const showError = useCallback((error: AxiosError) => {
    if (error.response) {
      toast.error(
        <b>
          {translations(error.response.status === StatusCodes.NOT_FOUND ? 'client_not_found' : 'something_went_wrong')}
        </b>,
      );
    }
    // FYI: translations in deps cause endless re-rendering
    // eslint-disable-next-line
  }, []);

  const handleScan = useCallback(
    async (uniqueId: string | null) => {
      let isUserScanned = false;
      if (uniqueId && !isUserScanned) {
        getApplicationUsers(uniqueId)
          .then((applicationUsers) => {
            isUserScanned = true;
            setCode('');
            if (applicationUsers.length === 1) {
              setApplicationUser(applicationUsers[0]);
            }
          })
          .catch((error: AxiosError) => {
            showError(error);
          });
      }
    },
    [setApplicationUser, showError],
  );

  useEffect(() => {
    if (uId) {
      handleScan(uId);
    }
  }, [handleScan, uId]);

  useEffect(() => {
    if (code) {
      handleScan(code);
    }
  }, [handleScan, code]);

  return (
    <>
      <Flex py={0} height="100%" wrap="wrap">
        <VStack
          pr={4}
          py={4}
          display={!applicationUser || scanMode === ItemAssignmentSelectMode.SCAN_ITEM ? undefined : 'none'}
        >
          {!applicationUser ? <QrCodeScanner onScan={setCode} /> : null}
        </VStack>

        {!applicationUser || !restaurantId ? (
          <Center>
            <form onSubmit={handleSubmit}>
              <RestaurantSelect
                userRestaurants={restaurants}
                restaurantId={restaurantId}
                setRestaurantId={setRestaurantId}
                styles={relevoAssignmentRestaurantSelectStyle}
              />
              <Autocomplete placeholder={translations('client_id_input_label')} mode={mode} />
            </form>
          </Center>
        ) : null}
      </Flex>
      {applicationUser && restaurantId && UserSelectMode.ASSIGNMENT === mode ? (
        <>{scanMode === ItemAssignmentSelectMode.SCAN_ITEM ? <RelevoScanner /> : <SelectCategories />}</>
      ) : null}
    </>
  );
};

export default UserSelect;
